<template>
  <div class="grid-x grid-margin-x">
    <div class="cell medium-6">
      <card-item :title="$t('interface.text')" icon="icon-interface-font">
        <ab-input-field
          :label="$t('interface.title')"
          v-model="settings.login_screen_title"
        ></ab-input-field>
        <ab-input-field
          :label="$t('interface.subtitle')"
          v-model="settings.login_screen_subtitle"
        ></ab-input-field>
      </card-item>
    </div>

    <div class="cell medium-6">
      <card-item
        :title="$t('interface.background')"
        icon="icon-interface-background-image"
      >
        <ab-select
          :label="$t('interface.type')"
          :options="loginTypeBackground"
          v-model="settings.login_screen_bg_type"
        ></ab-select>
        <div class="spacer--20"></div>
        <ab-color-picker
          v-if="settings.login_screen_bg_type === 'color'"
          :value.sync="settings.login_screen_bg_color"
          :label="$t('interface.background_color')"
          type="large"
        ></ab-color-picker>

        <div class="" v-if="settings.login_screen_bg_type === 'image'">
          <div class="spacer--30"></div>
          <div class="vue-dropzone-single">
            <ab-dropzone
              :data="settings.login_screen_bg_image"
              @image-removed="settings.login_screen_bg_image = {}"
            />
          </div>
        </div>
      </card-item>
      <div class="spacer--20"></div>
      <card-item :title="$t('interface.logo')" icon="icon-interface-logo">
        <div class="vue-dropzone-single">
          <ab-dropzone
            :data="content.login_screen_logo"
            @image-removed="settings.login_screen_logo = {}"
          />
        </div>
      </card-item>
      <div class="spacer--20"></div>
      <card-item :title="$t('interface.favicon')" icon="icon-interface-logo">
        <div style="color:#cecece; margin-bottom:30px;">{{ $t('interface.favicon_size_requirements') }}</div>
        <div class="vue-dropzone-single">
          <ab-dropzone
            :data="content.login_screen_favicon"
            @image-removed="imageRemoved()"
            @image-added="imageAdded()"
          />
        </div>
      </card-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InterfaceSettingsLoginScreen',
  props: ['content'],
  data () {
    return {
      loginTypeBackground: [
        {
          title: this.$t('app.background_color'),
          value: 'color'
        },
        {
          title: this.$t('app.background_image'),
          value: 'image'
        }
      ]
    }
  },
  methods: {
    imageAdded () {
      this.settings.login_screen_favicon.faviconIsAdded = true

      delete this.settings.login_screen_favicon.faviconIsRemoved
    },
    imageRemoved () {
      this.settings.login_screen_favicon = {
        faviconIsRemoved: true
      }

      delete this.settings.login_screen_favicon.faviconIsAdded
      delete this.settings.login_screen_favicon.favicon_formats
    }
  },
  mounted () {
    if (Array.isArray(this.content.login_screen_logo)) {
      this.settings.login_screen_logo = {}
    }
    if (Array.isArray(this.content.login_screen_favicon)) {
      this.settings.login_screen_favicon = {}
    }
    if (Array.isArray(this.content.login_screen_bg_image)) {
      this.settings.login_screen_bg_image = {}
    }
  },
  computed: {
    settings: {
      get () {
        return this.content
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
